
.person-image {
  object-fit: cover;
  aspect-ratio: 1/1;
  min-width: 50px;
}

.person-header {
  border-radius: 0;
  border: none !important;

  .actn-icon {
    font-size: 1.3rem !important;
  }

  .actn-text {
    font-size: 0.7rem !important;
    line-height: 0.7rem !important;
  }

  .vitae {
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    display: -webkit-box;

    -webkit-box-orient: vertical;
  }
}

@media(max-width: 767px) {
  .person-header {
    border-radius: 0;
    border: none !important;
  }
}

.note-modal {
  -moz-box-shadow: -4px 4px 5px #b6b6b6;
  -webkit-box-shadow: -4px 4px 5px #b6b6b6;
  box-shadow: -4px 4px 5px #b6b6b6;
}

